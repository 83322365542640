<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <Loader v-if="!eventInfo" />
      <div class="dashboard__container--header flex justify-space-between align-end" v-if="eventInfo">
        <div class="flex align-start flex-column">
          <div class="nav__logo" style="color:white;">
            J<em>u</em>mp Pipeline
          </div>
          <div style="font-size:1.6rem; color:white;" class="strong" v-if="eventInfo && eventInfo.venue_title">
            {{eventInfo.venue_title}}<span v-if="eventInfo.venue && eventInfo.venue.shortCode"> - {{eventInfo.venue.shortCode}}</span>
          </div>
          <div style="color:white;" v-if="eventInfo && eventInfo.venue && eventInfo.venue.address">
            <span>{{eventInfo.venue.address.street_number}}</span>
            <span> {{eventInfo.venue.address.street}}</span>
            <div>
              <span>{{eventInfo.venue.address.city}}</span>
              <span>, {{eventInfo.venue.address.state}}</span>
              <span>{{eventInfo.venue.address.zip}}</span>
            </div>
          </div>
        </div>
        <!-- <div class="flex">
          <router-link :to="`/tracker/${$route.params.id}`">
            <button class="btn ml-4 mb-2" v-bind:class="{ 'btn__primary': isTracker, 'btn__ghost': !isTracker }">Tracker</button>
          </router-link>
          <router-link :to="`/tracker/${$route.params.id}/shifts`">
            <button class="btn ml-4 mb-2" v-bind:class="{ 'btn__primary': isShifts, 'btn__ghost': !isShifts }">Shifts and Hired Workers</button>
          </router-link>
        </div> -->
      </div>
      <router-view :key="$route.params.id" />
    </div>
  </div>
</template>

<style scoped>
.dashboard {
  background: rgb(20 25 32 / 93%) !important;
  padding-top: 2rem;
  padding-bottom: 4rem;
  min-height: calc(100vh - 6rem);
}
.fa-2x {
  font-size: 2rem;
}
h5 {
  font-size: 1.6rem;
  opacity: 1;
}
input {
  font-size: 1.4rem;
  background: #eee;
  border: none;
}
/* .btn__flat {
  color: white;
} */
.dashboard__container--header {
  align-items: flex-start;
}

.cancelled h5 {
    color: red !important;
  }
  .dashboard__container {
    max-width: 90%;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'trackerHome',
  computed: {
    ...mapState(['currentUser', 'userProfile', 'eventInfo']),
    
    isTracker() {
      return this.$route.name == 'Tracker';
    },
    isShifts() {
      return this.$route.name == 'Shifts';
    },
  },
  created () {
    this.$store.dispatch("getEventFromIdAss", this.$route.params.id);
  },
  components: {
    Loader,
  },

  methods: {
    goBack() {
      router.go(-1)
    },
  },
  beforeDestroy () {
    console.log(this)
  }
}
</script>